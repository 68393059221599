import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Topbar = () => {
  const [name, setName] = useState('');
  const [login, setLogin] = useState(false);
  let storeData = useSelector((storeData) => {
    return storeData.signUpDetails;
  });

  useEffect(() => {
    setName(storeData[storeData.length - 1]?.name);
    setLogin(localStorage.getItem('login'));
  }, [storeData]);

  function changeLocal() {
    setName('');
    setLogin(false);
  }

return (
    <div>
      <div className='topBar'>
        <div className='moving-message-container'>
          <div className="moving-message">
            Welcome to our Nightress! Enjoy up to 50% off on selected items!
          </div>
        </div>
      </div>
    </div>
);

};

export default Topbar;
