import { Box, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Filters } from '../Filters/Filters';
import { ProductCard } from '../ProductCard/ProductCard';
import { Skeleton } from '@chakra-ui/react';

export const ProductList = ({ products, addToCart, isLoading, images }) => {
  const [visibleProducts, setVisibleProducts] = useState(12); // State for number of visible products

  const loadMoreProducts = () => {
    setVisibleProducts((prevCount) => prevCount + 12); // Load 12 more products on each click
  };

  return (
    <div style={{ width: "100%", padding: "0px 20px", marginBottom: "30px" }}>
      <Box className='productListTitle' h={'30px'} paddingTop={'4px'} paddingBottom={'25px'} display="flex" justifyContent={'space-between'} borderTop='1px solid gray' borderBottom={'1px solid gray'} mb='20px'>
        <Box display="flex" alignItems={'center'} height={'20px'}>
          <Box paddingRight={'10px'} borderRight={'1px solid gray'}>
            <h6>ALL</h6>
          </Box>
          <Box paddingLeft={'10px'}>
            <h6>{products.length} STYLES FOUND</h6>
          </Box>
        </Box>
      </Box>

      {isLoading ? (
        <Box display={"grid"} gridTemplateColumns={{ base: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }} gridRowGap={"15px"} gridColumnGap={"15px"}>
          {/* Skeleton loading UI */}
          {[...Array(12)].map((_, index) => (
            <Skeleton key={index} height="350px" />
          ))}
        </Box>
      ) : (
        <Box display={"grid"} gridTemplateColumns={{ base: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }} gridRowGap={"15px"} gridColumnGap={"15px"} padding="0px 10px">
          {products.slice(0, visibleProducts).map((elem, index) => (
            <ProductCard key={index} data={elem} addToCart={addToCart} images={images} />
          ))}
        </Box>
      )}

      {visibleProducts < products.length && (
        <Box display="flex" justifyContent="center" mt="20px">
          <Button 
            backgroundColor="rgb(252,100,134)"
            color={"white"}
            colorScheme={'rgb(252,100,134)'} 
            onClick={loadMoreProducts} size="lg">
            Load More
          </Button>
        </Box>
      )}
    </div>
  );
};