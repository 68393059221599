import React from 'react';
import { Link } from 'react-router-dom';
import './Landing.css';

const Trending = () => {
  return (
    <div className='tranding-cont'>
      <h1 className='title'>TRENDING  &nbsp;CATEGORIES</h1>
      <div className='tranding-img'>
        <Link to="/products">
          <picture>
            <source media="(max-width: 768px)" srcSet="/assets/images/mobile/5.png" />
            <img src="/assets/images/Spotlight_category/toop.png" alt="Category 1" />
          </picture>
        </Link>

        <Link to="/nightwears">
          <picture>
            <source media="(max-width: 768px)" srcSet="/assets/images/mobile/1.png" />
            <img src="/assets/images/Spotlight_category/nightwear.png" alt="Category 2" />
          </picture>
        </Link>

        <Link to="/partywears">
          <picture>
            <source media="(max-width: 768px)" srcSet="/assets/images/mobile/3.png" />
            <img src="/assets/images/Spotlight_category/partywear.png" alt="Category 3" />
          </picture>
        </Link>

        <Link to="/">
          <picture>
            <source media="(max-width: 768px)" srcSet="/assets/images/mobile/4.png" />
            <img src="/assets/images/Spotlight_category/lingury.png" alt="Category 4" />
          </picture>
        </Link>
      </div>
    </div>
  );
};

export default Trending;
