import React from "react";
import styles from "./Cart.module.css";
import CartNavbar from "../../Components/CartAndCheckout/CartNavbar";
import CartItemsCard from "../../Components/CartAndCheckout/CartItemsCard";
import Loading from "../../Components/CartAndCheckout/Loading";
import CartFooter from "../../Components/CartAndCheckout/CartFooter";
import EmptyCart from "../../Components/CartAndCheckout/EmptyCart";
import { Box, Alert, AlertIcon, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import WebAPI from "../../env";
import { useNavigate } from "react-router-dom";

function Cart() {
  const [CartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [isCheckingOut, setIsCheckingOut] = useState(false); 
  const [CartSubTotal, setCartSubTotal] = useState(0);
  const [deleteAlertTime, setDeleteAlertTime] = useState(true);
  const [isDonated, setIsDonated] = useState(300);
  const navigate = useNavigate();

  let InitialAddressForm = {
    name: "",
    mobile: "",
    localaddress: "",
    email:"",
    city:"",
  };
  let [AddressDetail, setAddressDetails] = useState(InitialAddressForm);
  let [IncompleteAddress, setIncompleteAddress] = useState(false);

  useEffect(() => {
    fetchCartItems();
  }, []);
  
  const fetchCartItems = async () => {
    try {
      const userId = localStorage.getItem("userId") || sessionStorage.getItem("userId");
  
      if (!userId) {
        console.error("No userId found in localStorage or sessionStorage.");
        setIsLoading(false);
        return;
      }
  
      const response = await fetch(`${WebAPI}/cart/${userId}`);
      
      if (response.status === 404) {
        // If the cart is not found, handle it
        setCartItems([]); // Set an empty array for the cart items
        setIsLoading(false);
        return;
      }
  
      const data = await response.json();
  
      if (Array.isArray(data)) {
        setCartItems(data); // Make sure CartItems is always an array
      } else {
        setCartItems([]); // Set an empty array if the response is not an array
      }
      
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setIsLoading(false);
      setCartItems([]); // Set an empty array on error
    }
  };
    

  const AddressFormInput = (e) => {
    const { name, value } = e.target;
    setAddressDetails({ ...AddressDetail, [name]: value });
  };

  useEffect(() => {
    const subTotal = CartItems.reduce((acc, ele) => ele.product.price1 * ele.quantity + acc, 0);
    setCartSubTotal(subTotal);
  }, [CartItems]);

  const DeleteCartItem = async (id) => {
    await fetch(`${WebAPI}/cart/${id}`, { method: "DELETE" });
    setCartItems(CartItems.filter((item) => item._id !== id));
    DeleteAlert();
  };

  const updateQuantity = (id, newQuantity) => {
    const updatedCartItems = CartItems.map(item => {
      if (item._id === id) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setCartItems(updatedCartItems);
  };

  const DeleteAlert = () => {
    setDeleteAlertTime(false);
    setTimeout(() => {
      setDeleteAlertTime(true);
    }, 1500);
  };

  const handleCheckout = async () => {
    const { name, mobile, localaddress, city, email } = AddressDetail;
    if (!name || !mobile || !localaddress || !city || !email) {
      setShowAlert(true); // Show alert if fields are incomplete
      return;
    }

    setShowAlert(false);
    setIsCheckingOut(true); // Show loader
    const totalAmount = CartSubTotal + isDonated;
    const userId = localStorage.getItem("userId");
    if (!userId) {
      console.error("User ID not found");
      setIsCheckingOut(false); // Hide loader if user ID is not found
      return;
    }

    if (name && mobile && localaddress) {
      const orderData = {
        customerName: name,
        customerEmail: email,
        customerCity: city,
        customerPhone: mobile,
        customerAddress: localaddress,
        cartItems: CartItems,
        totalAmount: totalAmount,
        paymentMethod: "Cash on Delivery",
        orderStatus: "Pending",
        orderDate: new Date().toISOString(),
        userId: userId,
      };

      try {
        const response = await fetch(`${WebAPI}/checkout`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(orderData),
        });

        if (response.status === 201) {
          const result = await response.json();
          console.log("Order Saved:", result);
          localStorage.removeItem("PaymentDetails");
          navigate("/thankyou"); // Redirect to Thank You page
        } else {
          console.error("Failed to place the order");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsCheckingOut(false); // Hide loader once the process is finished
      }
    } else {
      setIncompleteAddress(true);
      setIsCheckingOut(false); // Hide loader if address is incomplete
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!Array.isArray(CartItems) || CartItems.length === 0) {
    return (
      <>
        <CartNavbar CartIconStyle={true} />
        <EmptyCart /> 
        <CartFooter />
      </>
    );
  }

  return (
    <div id={styles["cart-main-div"]}>
      <CartNavbar  />

      <Box
        margin={"auto"}
        w="100%"
        position="fixed"
        transition=".3s"
        zIndex="5"
        style={deleteAlertTime ? { opacity: 0 } : { opacity: 1 }}
      >
        <Alert w="300px" margin="auto" status="warning">
          <AlertIcon />
          1 item deleted from cart
        </Alert>
      </Box>

      <div className={styles.CartBox}>
        <div className={styles.CartDescriptionAndPaymentDetails}>
          <div className={styles.CartItemDescription}>
            <p>Your Cart</p>
            {CartItems.map((ele) =>
              ele.product ? (
                <CartItemsCard
                  key={ele._id}
                  Cartdata={{
                    id: ele._id,
                    title: ele.product.title || "Unknown Product",
                    price1: ele.product.price1,
                    qty: ele.quantity,
                    img1: ele.product.image1,
                    category: ele.product.category,
                    color: ele.product.colors ? ele.product.colors[0] : "N/A",
                    uniqueId: ele.product._id,
                    size: ele.size,
                  }}
                  DeleteFunction={DeleteCartItem}
                  updateQuantity={updateQuantity}
                />
              ) : (
                <p key={ele._id}>Product details are missing</p>
              )
            )}
          </div>

          {/* Delivery Address Inputs */}
          <div className={styles.CartPaymentDescriptionDiv}>
            <p>Delivery Address</p>
            <div className={styles.CartPaymentDescription}>
              <div className={styles.AddressField}>
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  name="name"
                  value={AddressDetail.name}
                  onChange={AddressFormInput}
                  required
                />
              </div>
              <div className={styles.AddressField}>
                <label htmlFor="mobile">Phone Number* (03xxxxxxxxx)</label>
                <input
                  type="text"
                  name="mobile"
                  value={AddressDetail.mobile}
                  onChange={AddressFormInput}
                  required
                />
              </div>
              <div className={styles.AddressRow1}>
  <div className={styles.AddressField1}>
    <label htmlFor="email">Email*</label>
    <input
      type="email"
      name="email"
      value={AddressDetail.email}
                  onChange={AddressFormInput}
    />
  </div>
  <div className={styles.AddressField1}>
    <label htmlFor="text">City*</label>
    <input
      type="text"
      name="city"
      value={AddressDetail.city}
                  onChange={AddressFormInput}
    />
  </div>
</div>
              <div className={styles.AddressField}>
                <label htmlFor="localaddress">Address*</label>
                <textarea
                  name="localaddress"
                  value={AddressDetail.localaddress}
                  onChange={AddressFormInput}
                  cols="30"
                  rows="3"
                  required
                ></textarea>
              </div>
            </div>
            <div className={styles.EstimatedDelivery}>
              <p>Estimated Delivery Time</p>
              <p>Pakistan : 3-5 business days.</p>
            </div>
            {/* Payment Details */}
            <p>Payment Details</p>
            
            <div className={styles.CartPaymentDescription}>
              <div className={styles.PriceDetailDiv}>
                <div className={styles.ProductMRP}>
                  <p>Sub Total</p>
                  <p>Rs. {CartSubTotal.toFixed(2)}</p>
                </div>
                <div className={styles.DonationAmonut}>
                  <p>Delivery Charges</p>
                  <p>Rs. {isDonated}</p>
                </div>
              </div>
              <div className={styles.TotalAmountDiv}>
                <p>Total</p>
                <p>Rs. {(CartSubTotal + isDonated).toFixed(2)}</p>
              </div>
              <div className={styles.PlaceOrderDiv}>
  {showAlert && (
    <Box w="100%" p={4}>
      <Alert status="error">
        <AlertIcon />
        Please complete your address information!
      </Alert>
    </Box>
  )}
 {isCheckingOut ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding:'10px' }}>
      <Spinner size="lg" /> {/* Centered Spinner */}
    </div>
  ) : (
    <button onClick={handleCheckout}>CHECKOUT</button>
  )}</div>


            </div>
          </div>
        </div>
      </div>
      
    </div>
    
  );
}

export default Cart;
