import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const InstaShopCard = ({ url, title, price, id }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleGetTheLook = () => {
    navigate(`/products/${id}`); // Navigate to the product page
  };

  return (
    <div className='instaCardRel'>
      <img src={url} alt="avatar" className='instaShopImage'/>
      <div className='instaCardAbs'>
      <span className="product-title">{title}</span>  {/* Add a class to the title */}
        <p>{price}</p>
        <button onClick={handleGetTheLook}>GET THE LOOK</button> {/* Attach onClick handler */}
      </div>
    </div>
  );
};

export default InstaShopCard;
