
import React from "react";
import styles from "./Cart.module.css";
import CartNavbar from "../../Components/CartAndCheckout/CartNavbar";
import CartItemsCard from "../../Components/CartAndCheckout/CartItemsCard";
import Loading from "../../Components/CartAndCheckout/Loading";
import CartFooter from "../../Components/CartAndCheckout/CartFooter";
import EmptyCart from "../../Components/CartAndCheckout/EmptyCart";
import { Box, Alert, AlertIcon, useToast } from "@chakra-ui/react";
import { useEffect, useState,  } from "react";
import WebAPI from "../../env";
import { useNavigate } from "react-router-dom";

function Checkout() {
  const [CartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [CartSubTotal, setCartSubTotal] = useState(0);
  const [deleteAlertTime, setDeleteAlertTime] = useState(true);
  const [isDonated, setIsDonated] = useState(300);
  const navigate = useNavigate();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [address, setAddress] = useState({ name: "", mobile: "", address: "", email: "", city: "" });
  const toast = useToast();

  let InitialAddressForm = {
    name: "",
    mobile: "",
    localaddress: "",
    email:"",
    city:"",
  };
  let [AddressDetail, setAddressDetails] = useState(InitialAddressForm);
  let [IncompleteAddress, setIncompleteAddress] = useState(false);

  useEffect(() => {
    fetchCartItems();
  }, []);
  useEffect(() => {
    const storedPaymentDetails = localStorage.getItem("PaymentDetails");
    if (storedPaymentDetails) {
      setPaymentDetails(JSON.parse(storedPaymentDetails));
    }
  }, []);
  const fetchCartItems = async () => {
    try {
      const response = await fetch(`${WebAPI}/cart`);
      const data = await response.json();
      setCartItems(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setIsLoading(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const AddressFormInput = (e) => {
    const { name, value } = e.target;
    setAddressDetails({ ...AddressDetail, [name]: value });
  };
  useEffect(() => {
    const storedPaymentDetails = localStorage.getItem("PaymentDetails");
    if (storedPaymentDetails) {
      const parsedDetails = JSON.parse(storedPaymentDetails);
      setPaymentDetails(parsedDetails);
    }
  }, []);
  
  useEffect(() => {
    if (paymentDetails && paymentDetails.cartItems.length > 0) {
      const subTotal = paymentDetails.cartItems.reduce((acc, item) => {
        return acc + (item.price * item.quantity);
      }, 0);
      setCartSubTotal(subTotal);
    } else {
      setCartSubTotal(0); // Reset if no items are available
    }
  }, [paymentDetails]);
  

  const DeleteCartItem = async (id) => {
    await fetch(`${WebAPI}/cart/${id}`, { method: "DELETE" });
    setCartItems(CartItems.filter((item) => item._id !== id));
    DeleteAlert();
  };

  const updateQuantity = (id, newQuantity) => {
    const updatedCartItems = CartItems.map(item => {
      if (item._id === id) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setCartItems(updatedCartItems); // Update the cart items
  };

  const DeleteAlert = () => {
    setDeleteAlertTime(false);
    setTimeout(() => {
      setDeleteAlertTime(true);
    }, 1500);
  };

  const handleCheckout = async () => {
    const { name, mobile, localaddress, city , email } = AddressDetail;
    if (!name || !mobile || !localaddress || !city || !email) {
      setShowAlert(true); // Show alert if fields are incomplete
      return;
    }
  
    // Hide the alert if the fields are filled
    setShowAlert(false);
    const totalAmount = CartSubTotal + isDonated;
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.error("User ID not found");
      return;
    }
    // Check if address fields are complete
    if (name && mobile && localaddress) {
      const orderData = {
        customerName: name,
        customerEmail: email,
        customerCity: city,
        customerPhone: mobile,
        customerAddress: localaddress,
        cartItems: CartItems,
        totalAmount: totalAmount,
        paymentMethod: "Cash on Delivery",
        orderStatus: "Pending",
        orderDate: new Date().toISOString(),
        userId: userId,

      };

      try {
        const response = await fetch(`${WebAPI}/directcheckout`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(orderData),
        });

        if (response.status === 201) {
          const result = await response.json();
          console.log("Order Saved:", result);
          localStorage.removeItem("PaymentDetails");
          navigate("/thankyou"); // Redirect to the Thank You page
        } else {
          console.error("Failed to place the order");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setIncompleteAddress(true);
    }
  };


  if (isLoading) {
    return <Loading />;
  }

  if (!paymentDetails || paymentDetails.cartItems.length === 0) {
    return (
      <>
        <CartNavbar CartIconStyle={true} />
        <EmptyCart /> 
        <CartFooter />
      </>
    );
  }

  return (
    <div id={styles["cart-main-div"]}>
      <CartNavbar  />

      <Box
        margin={"auto"}
        w="100%"
        position="fixed"
        transition=".3s"
        zIndex="5"
        style={deleteAlertTime ? { opacity: 0 } : { opacity: 1 }}
      >
        <Alert w="300px" margin="auto" status="warning">
          <AlertIcon />
          1 item deleted from cart
        </Alert>
      </Box>

      <div className={styles.CartBox}>
        <div className={styles.CartDescriptionAndPaymentDetails}>
          <div className={styles.CartItemDescription}>
            <p>Checkout</p>
            {paymentDetails && paymentDetails.cartItems.length > 0 ? (
                <CartItemsCard
                  key={paymentDetails.cartItems[0]._id}
                  Cartdata={{
                    id: paymentDetails.cartItems[0]._id,
                    title: paymentDetails.cartItems[0].title || "Unknown Product",
                    price1: paymentDetails.cartItems[0].price,
                    qty: paymentDetails.cartItems[0].quantity,
                    img1: paymentDetails.cartItems[0].image,
                    category: paymentDetails.cartItems[0].category,
                    color: paymentDetails.cartItems[0].colors ? paymentDetails.cartItems[0].colors[0] : "N/A",
                    uniqueId: paymentDetails.cartItems[0]._id,
                    size: paymentDetails.cartItems[0].size,
                  }}
                  DeleteFunction={DeleteCartItem}
                  updateQuantity={updateQuantity}
                />
              ) : (
                <p key={paymentDetails.cartItems[0]._id}>Product details are missing</p>
              )
}
            
          </div>

          {/* Delivery Address Inputs */}
          <div className={styles.CartPaymentDescriptionDiv}>
            <p>Delivery Address</p>
            <div className={styles.CartPaymentDescription}>
              <div className={styles.AddressField}>
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  name="name"
                  value={AddressDetail.name}
                  onChange={AddressFormInput}
                  required
                />
              </div>
              <div className={styles.AddressField}>
                <label htmlFor="mobile">Phone Number* (03xxxxxxxxx)</label>
                <input
                  type="text"
                  name="mobile"
                  value={AddressDetail.mobile}
                  onChange={AddressFormInput}
                  required
                />
              </div>
              <div className={styles.AddressRow1}>
  <div className={styles.AddressField1}>
    <label htmlFor="email">Email*</label>
    <input
      type="email"
      name="email"
      value={AddressDetail.email}
                  onChange={AddressFormInput}
    />
  </div>
  <div className={styles.AddressField1}>
    <label htmlFor="text">City*</label>
    <input
      type="text"
      name="city"
      value={AddressDetail.city}
                  onChange={AddressFormInput}
    />
  </div>
</div>
              <div className={styles.AddressField}>
                <label htmlFor="localaddress">Address*</label>
                <textarea
                  name="localaddress"
                  value={AddressDetail.localaddress}
                  onChange={AddressFormInput}
                  cols="30"
                  rows="3"
                  required
                ></textarea>
              </div>
            </div>
            <div className={styles.EstimatedDelivery}>
              <p>Estimated Delivery Time</p>
              <p>Pakistan : 3-5 business days.</p>
            </div>
            {/* Payment Details */}
            <p>Payment Details</p>
            
            <div className={styles.CartPaymentDescription}>
              <div className={styles.PriceDetailDiv}>
                <div className={styles.ProductMRP}>
                  <p>Sub Total</p>
                  <p>Rs. {CartSubTotal.toFixed(2)}</p>
                </div>
                <div className={styles.DonationAmonut}>
                  <p>Delivery Charges</p>
                  <p>Rs. {isDonated}</p>
                </div>
              </div>
              <div className={styles.TotalAmountDiv}>
                <p>Total</p>
                <p>Rs. {(CartSubTotal + isDonated).toFixed(2)}</p>
              </div>
              <div className={styles.PlaceOrderDiv}>
  {showAlert && (
    <Box w="100%" p={4}>
      <Alert status="error">
        <AlertIcon />
        Please complete your address information!
      </Alert>
    </Box>
  )}
  <button onClick={handleCheckout}>CHECKOUT</button>
</div>


            </div>
          </div>
        </div>
      </div>
      
    </div>
    
  );
}

export default Checkout;