import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid'; 
import './App.css';
import Chatbot from './Components/ChatBot/Chatbot';
import Navbar from './Components/Navbar/Navbar';
import AllRoutes from './Pages/AllRoutes/AllRoutes';

function App() {
  useEffect(() => {
    // Check if a user ID already exists in localStorage
    let userId = localStorage.getItem('userId');

    // If no user ID exists, generate a new one and store it
    if (!userId) {
      userId = uuidv4(); // Generate a unique ID
      localStorage.setItem('userId', userId);

      setTimeout(() => {
        localStorage.removeItem('userId');
      }, 600 * 60 * 1000); // 10 hours in milliseconds
    }

    console.log("User ID: ", userId); // For testing purposes
  }, []);

  return (
    <div className="app">
      <AllRoutes />
    </div>
  );
}

export default App;
