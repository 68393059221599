import React, { useEffect, useState } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Text } from '@chakra-ui/react';
import WebAPI from '../../../env';

export const CategoryList = ({ changeFilter }) => {
  const [categories, setCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(true); // State to manage accordion open/close

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${WebAPI}/category`);
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div style={{ marginTop: '0%', width: '17%' }}>
      <Accordion allowMultiple defaultIndex={[0]} onChange={(index) => setIsOpen(index.length > 0)}>
        <AccordionItem isExpanded={isOpen}>
          <h2>
            <AccordionButton borderTop={'1.5px solid gray'} onClick={() => setIsOpen(!isOpen)}>
              <Box as="span" flex='1' textAlign='left' fontSize={'l'}>
                Categories
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {categories.map((category, index) => (
              <Box key={index} h={'25px'} display="flex" alignItems={'center'}>
                <input
                  type="checkbox"
                  id={`category-${index}`}
                  onChange={(e) => changeFilter('category', category._id)} // Apply filter only on checkbox change
                />
                <label htmlFor={`category-${index}`} style={{ marginLeft: '8px', cursor: 'default' }}>
                  <Text
                    fontSize={'sm'}
                    ml={2} // Margin left for spacing
                    cursor="pointer" // Ensure the text cursor changes when hovering
                  >
                    {category.name}
                  </Text>
                </label>
              </Box>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
