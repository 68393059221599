import React, { useEffect } from 'react'
import './Landing.css'
import {GrFormNext,GrFormPrevious} from 'react-icons/gr'

const Banner = () => {
    var box = document.querySelector('.carousel-images');
    // var box = document.querySelector('.carousel-images');
     
    const handlePrev= ()=>{
        let box = document.querySelector('.carousel-images');
        // let boxWidth = box.clientWidth;
        let boxWidth = box.clientWidth;
        console.log(boxWidth,'box width');
        box.scrollLeft -= boxWidth;
        // box.scrollWidth
    }
    const handleNext= ()=>{
        // var w = window.innerWidth;
        // console.log(w)
        let box = document.querySelector('.carousel-images');
        let boxWidth = box.clientWidth;
        box.scrollLeft += boxWidth;
        if(box.scrollLeft >= boxWidth*4){
            box.scrollLeft = 0
        }
    }

    useEffect(()=>{
        setInterval(()=>{   
            handleNext();
        }, 4000)
    },[])

  return (
    <div className='Banner-carousel'>
        <button className='pre-btn' onClick={handlePrev}><p><GrFormPrevious size={'1.8rem'}/></p></button>
        <button className='next-btn' onClick={handleNext}><p><GrFormNext size={'1.8rem'}/></p></button>
        <div className='carousel-images' id='images'>
            <img src="/assets/images/Banner/5.png" alt="" />
            <img src="/assets/images/Banner/6.png" alt="" />
            <img src="/assets/images/Banner/1.png" alt="" />
            <img src="/assets/images/Banner/2.png" alt="" />
            <img src="/assets/images/Banner/4.png" alt="" />
        </div>
    </div>
  )
}

export default Banner
