import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { Input, InputGroup, Button, InputRightElement } from "@chakra-ui/react";
import "./SingleProduct.css";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import WebAPI from "../../env";
import { useNavigate } from "react-router-dom";


const NightyProduct = () => {
  // delivery date
  const min = 10;
  const max = 20;
  let date1 = Math.floor(Math.random() * 10) + 1;
  let date2 = Math.floor(Math.random() * (max - min)) + min;
  console.log(date1, date2);
  const navigate = useNavigate();

  const [product, setProduct] = useState({});
  console.log("product: ", product);
  const [selectedSize, setSelectedSize] = useState("");
  const [isLoadingAddToCart, setIsLoadingAddToCart] = useState(false);
  const [isLoadingBuyItNow, setIsLoadingBuyItNow] = useState(false);
  const [selectedColor, setSelectedColor] = useState(""); // State for color
  const [checkActiveState, setCheckActiveState] = useState({ activeClass: null });
  const [showMessage, setMessage] = useState(false);
  const [checkColorState, setCheckColorState] = useState({ activeClass: null });
  const [show, setShow] = useState(false);

  const {id} = useParams();
  const handleClick = () => {
    setShow(!show);
    setMessage(!showMessage);
  };

  const toast = useToast()
  const fetchProduct = async () => {
    try {
      const response = await fetch(`${WebAPI}/nightyproduct/${id}`);
      const data = await response.json();
      setProduct(data);
    } catch (error) {
      console.error("Error fetching product:", error);
      toast({
        title: "Error fetching product",
        status: "error",
        duration: 2000,
        position: "top",
      });
    }
  };

  useEffect(() => {
    fetchProduct();
    window.scrollTo(0, 0);
  }, [id]);

  const setProductSizeHandleClick = (size, index) => {
    setSelectedSize(size); // Update selected size
    setCheckActiveState({ activeClass: index }); // Set active class for the selected size
};
const setProductColorHandleClick = (color, index) => {
  setSelectedColor(color);
  setCheckColorState({ activeClass: index });
};
useEffect(() => {
  if (product.colors && product.colors.length === 1) {
    setSelectedColor(product.colors[0]); 
  }
}, [product.colors]);
const buyItNow = (product) => {
  if (!selectedSize) {
    toast({
      title: "Please select a size before proceeding.",
      status: "warning",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
    return;
  }
  if (product.colors.length > 1 && !selectedColor) {
    toast({
      title: "PleasePlease select a color before proceeding.",
      status: "warning",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
    return;
  }
  setIsLoadingBuyItNow(true); 

  // Prepare cart item details
  const cartItemPayload = {
    product: product._id,
    productType: 'NightyProduct',
    color: selectedColor, // Add selected color
    quantity: 1,
    size: selectedSize, // Ensure selected size is included
    title: product.title,
    price: product.price1, // Assuming price1 is the final price
    image: product.image1, // Main product image
  };

  // Store product details in localStorage
  const paymentDetails = {
    cartItems: [cartItemPayload],
    Total: product.price1, // Set total price for now
  };
  localStorage.setItem("PaymentDetails", JSON.stringify(paymentDetails));

  // Redirect to checkout page
  navigate("/checkout");
  setIsLoadingBuyItNow(false);

};
const addToCart = async (e, product) => {
  e.preventDefault();
  setIsLoadingAddToCart(true); // Start loading
  const productType = 'NightyProduct'; 
  const userId = localStorage.getItem('userId');

  // Ensure that a size is selected before proceeding
  if (!selectedSize) {
    toast({
      title: "Please select a size before proceeding.",
      status: "warning",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
    setIsLoadingAddToCart(false); // Stop loading
    return;
  }

  if (product.colors.length > 1 && !selectedColor) {
    toast({
      title: "Please select a color before proceeding.",
      status: "warning",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
    setIsLoadingAddToCart(false); // Stop loading
    return; 
  }

  try {
    const response = await fetch(`${WebAPI}/cart`);
    const cartItems = await response.json();

    const isProductInCart = cartItems.some(
      (item) => item.product === product._id && item.size === selectedSize
    );

    if (isProductInCart) {
      toast({
        title: "Product is already in the cart.",
        status: "info",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    } else {
      const cartItemPayload = {
        product: product._id,
        productType,
        userId,
        quantity: 1,
        color: selectedColor,
        size: selectedSize, 
      };
      const addResponse = await fetch(`${WebAPI}/cart`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cartItemPayload),
      });

      if (addResponse.ok) {
        toast({
          title: "Added to cart.",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        navigate("/cart");
      } else {
        throw new Error("Failed to add to cart");
      }
    }
  } catch (error) {
    toast({
      title: "Error.",
      description: error.message,
      status: "error",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  } finally {
    setIsLoadingAddToCart(false); // Stop loading in the finally block to ensure it runs after try/catch
  }
};


return (
    
  <div style={{ width: "90%", margin: "20px auto",marginBottom:'3%' }}>
    
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        className="img-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "60%",
        }}
      >
        {product && Object.keys(product).length > 0 ? (
          <div>
          <img src={`${WebAPI}/uploads/${product.image1}`} alt="This is a product" />
          <img src={`${WebAPI}/uploads/${product.image2}`} alt="This is a product" />
          <img src={`${WebAPI}/uploads/${product.image3}`} alt="This is a product" />
          <img src={`${WebAPI}/uploads/${product.image4}`} alt="This is a product" />
        </div>
        ) : (
          <h2>No Dataa</h2>
        )}
      </div>
      <div style={{ width: "36%" }}>
      <h3 className="product-title">
{product.title}
</h3>
        <h3 className="price">
          
          <span className="currentPrice">Rs.  {product.price1}</span>&nbsp;&nbsp;
          {" "}<span className="originalPrice">Rs.  {product.price2}</span>
        </h3>
        <br></br>
        <hr></hr>
        <div className="break"></div>
        
<div className="color-selection">
{product.colors && product.colors.length > 0 ? (
<div className="color-container"> 
  {product.colors.map((color, index) => (
    <span key={index} className="color-swatch" style={{ marginRight: "10px" }}>
      <span
        className="color-circle"
        style={{
          backgroundColor: color.toLowerCase(),
          border: index === checkColorState.activeClass ? "2px solid black" : "1px solid gray",
          cursor: "pointer",
        }}
        onClick={() => setProductColorHandleClick(color, index)}
      ></span>
    </span>
  ))}
</div>
) : (
<p className="color-text">Color: {product.color}</p>
)}
</div>

        {/* size box */}
        <div className="size-container">
<p className="size-label">SIZE:</p>
<p className="size-guide">SIZE GUIDE</p>
</div>


<div className="size">
{product.sizes && product.sizes.map((size, index) => (
<p
  className={index === checkActiveState.activeClass ? "activeSize" : ""}
  key={index}
  onClick={() => setProductSizeHandleClick(size, index)}
>
  {size}
</p>
))}
</div>
        <hr></hr>

<div className="break"></div>

<div className="cartBtns">
  {isLoadingAddToCart ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding:'10px' }}>
    <Spinner size="lg" /> 
    </div>
      ) : (
    <p className="addToCart" onClick={(e) => addToCart(e, product)}>
      ADD TO CART
    </p>
  )}
</div>

<div className="break"></div>

<div className="cartBtns">
  {isLoadingBuyItNow ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding:'10px' }}>
    <Spinner size="lg" /> 
    </div>
  ) : (
    <p className="buyitnow" onClick={() => buyItNow(product)}>
      BUY IT NOW
    </p>
  )}
</div>
     {/* pincode */}

        <h3 style={{ color: "green", padding: "10px" }}>
          {show
            ? `Express Delivery between ${date1} Jan 2023 - ${date2} Jan 2023`
            : null}
        </h3>

       
        <div id="delivery">
          <p>
            <i class="fa-solid fa-people-arrows"></i> &nbsp;COD Available
          </p>
          <p>
            <i class="fa-solid fa-shield"></i>&nbsp; Secure Payment
          </p>
          <p>
            <i class="fa-solid fa-truck"></i>&nbsp; Secure Shipping
          </p>
        </div>

        <div className="break"></div>
        <div className="break"></div>
        {/* accordian */}
        <Accordion defaultIndex={[0]} allowMultiple>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box
          as="span"
          flex="1"
          textAlign="left"
          className="accordion-title" // Using CSS class
        >
          Description
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <h1 className="accordion-content">{product.description}</h1> {/* Using CSS class */}
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box
          as="span"
          flex="1"
          textAlign="left"
          className="accordion-title" // Using CSS class
        >
          Shipping & Returns
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <h1 className="accordion-content">
        Dispatch: Within 24 Hours Delivery time within India - 1-3*
        business days International delivery time - 7-10* business days
        Return/Exchange: If you are not completely satisfied with your
        purchase, simply select the option of return/exchange within 10
        days of receiving your order from your order details page and we
        will process your return, no questions asked.
      </h1>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
      </div>
    </div>
    {/* {success?
        <Container  fontSize="1rem" gap="3px" h={'50px'} width="250px" backgroundColor="green" color="white" display="flex" justifyContent ="center" alignItems="center" position="fixed" top="6%" left="45%"  borderRadius="8px">
        <AiFillCheckCircle fontSize={"1.2rem"}/> <Text>
            
            Added To Cart
            </Text>
    </Container>:""}

    {alert?
        <Container  fontSize="1rem" gap="3px" h={'50px'} width="250px" backgroundColor="red" color="white" display="flex" justifyContent ="center" alignItems="center" position="fixed" top="6%" left="45%"  borderRadius="8px">
        <MdError fontSize={"1.2rem"}/> <Text>
            
            Already in Cart!
            </Text>
        </Container>:""} */}
  </div>
);
};

export default NightyProduct;
