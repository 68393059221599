import React, { useEffect, useState } from 'react';
import styles from '../../Pages/Cart/Cart.module.css';
import { useNavigate } from 'react-router-dom';
import WebAPI from '../../env';
import { Link } from "react-router-dom";

function CartNavbar(props) {
  let NavigateToPage = useNavigate();

  let [username, setUsername] = useState('');
  let [trackingID, setTrackingID] = useState('');
  let [orderStatus, setOrderStatus] = useState(null); // To store the order status
  let [errorMessage, setErrorMessage] = useState(''); // To store error messages
  let [isMobile, setIsMobile] = useState(false); // State to detect mobile view

  // Detect screen size for mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set threshold for mobile
    };
    handleResize(); // Check on initial load
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Fetch username from localStorage on load
  useEffect(() => {
    let name = localStorage.getItem("userName") || 'Guest';
    setUsername(name);
  }, [username]);

  // Function to handle tracking ID input change
  const handleTrackingIDChange = (e) => {
    setTrackingID(e.target.value);
  };

  // Function to handle tracking API call
  const handleCheckTracking = async () => {
    if (trackingID) {
      try {
        const response = await fetch(`${WebAPI}/order-status/${trackingID}`);
        const data = await response.json();

        if (response.ok) {
          setOrderStatus(data.orderStatus);
          setErrorMessage('');
          setTimeout(() => {
            setOrderStatus(null);
          }, 5000);
        } else {
          setErrorMessage(data.message || 'Invalid Tracking ID');
          setOrderStatus(null);
          setTimeout(() => {
            setErrorMessage('');
          }, 5000);
        }
      } catch (error) {
        setErrorMessage('Error fetching order status.');
        setOrderStatus(null);
        setTimeout(() => {
          setErrorMessage('');
        }, 5000);
      }
    } else {
      alert('Please enter a valid Tracking ID');
    }
  };

  const navigate = useNavigate();

  return (
    <div>
      <div className={styles.CartNavbar}>
        {/* Site Logos */}
        <div className={styles.CartSiteLogo}>
          <img
            onClick={() => navigate('/')}
            style={{ cursor: 'pointer' }}
            src="/assets/images/nightress.png"
            alt="img"
          />
          <img
            onClick={() => navigate('/')}
            style={{ cursor: 'pointer' }}
            src="/assets/images/nightress.png"
            alt=""
          />
        </div>

        {/* Conditional Rendering for Tracking ID */}
        {isMobile ? (
          <div className={styles.MobileTrackNow}>
            <Link to={'/order-tracking'} className={styles.CheckButtons}
            >
              Track Order
            </Link>
          </div>
        ) : (
          <div className={styles.TrackingIDField}>
            <label htmlFor="trackingID">Tracking ID:</label>
            <input
              type="text"
              name="trackingID"
              value={trackingID}
              onChange={handleTrackingIDChange}
              placeholder="Track your previous order"
              className={styles.TrackingIDInput}
            />
            <button
              className={styles.CheckButton}
              onClick={handleCheckTracking}
            >
              Track
            </button>

            {/* Display Order Status */}
            {orderStatus && (
              <span className={styles.OrderStatus}>Order Status: {orderStatus}</span>
            )}

            {/* Display Error Message */}
            {errorMessage && (
              <span className={styles.ErrorMessage}>{errorMessage}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CartNavbar;
