import React, { useEffect, useState } from 'react';
import InstaShopCard from './InstaShopCard';
import WebAPI from "../../env"; // Import the WebAPI variable
import { Link } from 'react-router-dom';

const InstaShop = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${WebAPI}/product`); // Use WebAPI to fetch products
        const data = await response.json();
        // Only take the first seven products
        setProducts(data.slice(0, 7));
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
<div className='instaShop'>
  <h1 className='title'>NEW ARRIVALS</h1>
  <div>
    <div>
      <img src="/assets/images/Banner/fashion.png" alt="" />
    </div>
    <div className='instaShopCard'>
      {products.map((product) => (
        <InstaShopCard 
          key={product._id}
          url={`${WebAPI}/uploads/${product.image3}`}
          title={product.title}  // keep the title here
          price={`Rs. ${product.price1} -- ${product.discount ? `${product.discount}% OFF` : ''}`}
          description={product.description}
          id={product._id}
        />
      ))}
      <div style={{ 
        backgroundColor: '#ffe9e5', 
        alignItems: 'center', 
        display: 'flex', 
        justifyContent: 'center', 
        cursor: 'pointer', 
        padding: '10px' 
        }} 
        className="explore-more"
      >
        <Link to="/products">EXPLORE MORE</Link>
      </div>
    </div>
  </div>
</div>

  );
}

export default InstaShop;
