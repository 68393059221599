import React, { useState } from "react";
import "./Navbar.css";
import MidbarList from "./MidbarList";
import { BsHandbag } from 'react-icons/bs';
import { Link } from "react-router-dom";
import { HiMenu } from 'react-icons/hi';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";

const Midbar = ({ filters, changeFilters }) => {
  const [searchToggle, setSearchToggle] = useState(false);

  return (
    <div className="midBar">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className='hamburgerMenu'>
          <Menu>
            <MenuButton as={Button} background='none' colorScheme={'white'}>
              <HiMenu size={'1.7rem'} color='black' />
            </MenuButton>
            <MenuList>
              <MenuItem><Link to={'/products'}>Tops</Link></MenuItem>
              <MenuItem><Link to={'/nightwears'}>Nightwears</Link></MenuItem>
              <MenuItem><Link to={'/partywears'}>Partywears</Link></MenuItem>
              <MenuItem><Link to={''}>End of Sale</Link></MenuItem>
            </MenuList>
          </Menu>
        </div>
        <Link to={"/"}>
          <img
            // style={{ width: "200px", height: "auto", paddingright:"200px" }} /* Ensure logo resizes */
            src="/assets/images/nightress.png"
            alt="Logo"
          />
        </Link>
      </div>

      <div>
        <MidbarList filters={filters} changeFilters={changeFilters} />
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <ul style={{ display: 'flex', alignItems: 'center', listStyle: 'none', margin: 0, padding: 0 }}>
          <li style={{ marginRight: '15px' }}>
            <Link to={'/order-tracking'} style={{ textDecoration: 'none', color: 'black', fontSize: '16px' }}>
              Track Order &nbsp; |
            </Link>
          </li>
          <li>
            <Link to={'/cart'}>
              <BsHandbag style={{ fontSize: '27px' }} />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Midbar;
