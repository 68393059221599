import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./ThankYou.module.css"; // Import your CSS module

const ThankYou = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to home after 3 seconds
    const timer = setTimeout(() => {
      navigate("/"); // Redirect to home page
    }, 3000); // 3 seconds

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className={styles.thankYouContainer}>
      <div className={styles.tickCircle}>
        <span className={styles.tickMark}>✓</span> {/* Big Tick inside a circle */}
      </div>
      <h1 className={styles.thankYouTitle}>Thank You for Your Order!</h1>
      <p className={styles.thankYouMessage}>
        Your order has been placed successfully. We will contact you soon for delivery details.
      </p>
      <Link to="/" className={styles.homeLink}>
        Go to Home
      </Link> {/* Link to navigate back to home */}
    </div>
  );
};

export default ThankYou;
