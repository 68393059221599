import React from 'react';
import './BottomBar.css'; // Import your CSS file

const BottomBar = () => {
  return (
    <div className='nav-botton'>
      <div className='nav-item party-dresses'>
        <p className='mobile-only'>Party Dresses</p>
        <p className='desktop-only'>Party Dresses | Shop Now</p>
      </div>
      <div className='nav-item sale'>
      <p className='mobile-only'>Up to 50% off</p>
      <p className='desktop-only'>Sale | Up to 50% off</p>
      </div>
      <div className='nav-item shipping'>
        <p className='mobile-only'>Shop Now</p>
        <p className='desktop-only'>Shipping all over Pakistan</p>
      </div>
    </div>
  );
}

export default BottomBar;
