import { Link } from 'react-router-dom';

const MidbarCard = ({ list = [], images = [], links = [] }) => {
  return (
    <div className="midBarCard">
      <div className="items-container">
        {list.map((item, index) => (
          <div key={index} className="item-card">
            {/* Display image on top */}
            <Link to={links[index]}>
              <img src={images[index]} alt={item} className="item-image" />
            </Link>
            {/* Display item name underneath the image */}
            <p className="item-name">
              <Link to={links[index]}>{item}</Link> 
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MidbarCard;
