import React from 'react';
import './Landing.css';  // Make sure this CSS file includes the responsive styles

const CalltoAction = () => {
  return (
    <div className='callToAction'>
        <div className='cta-video'>
            <video height="" width="" poster="https://chantilly.myshopify.com/cdn/shop/files/sunsets-coll_1__CROP.jpg?v=1699884173&width=2400" src='https://youtu.be/S7Zrvt9IJ28'></video>
        </div>
        <div className='cta-content'>
            <h1>RIMZIM DADU X Niteress</h1>
            <hr/>
            <p>
              This season, Niteress joins forces with designer Rimzim Dadu to present a bold, one-of-a-kind, collaborative party line. In an ultra-glam synthesis of clean cuts, sharp silhouettes, and gleaming surfaces, the collection brings forth the designer’s stellar textures and techniques that fire up a host of classics to present a reimagined line-up of party favourites.
            </p>
            <button>SHOP THE COLLECTION</button>
        </div>
    </div>
  );
}

export default CalltoAction;
  