import { Box, Button, Skeleton, SkeletonCircle, SkeletonText, Text, useToast } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { TbTruckDelivery } from "react-icons/tb";
import { FcLikePlaceholder, FcLike } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import "./ProductCard.css";
import WebAPI from "../../../env";

export const ProductCard = ({ data, images, isLoading }) => {
  const [likeToggle, setLikeToggle] = useState(false);
  const [hoverImage, setHoverImage] = useState(false);
  const [imageLoading, setImageLoading] = useState(true); // State to track image loading
  const toast = useToast();

  // Fetch product images from the images prop
  const productImages = images && images[data._id] ? images[data._id] : {};
  const imgSrc = hoverImage && productImages.image2 ? productImages.image2 : productImages.image1;
  const navigate = useNavigate(); // Initialize navigate

  // Handle adding to cart
  const addToCart = (e, data) => {
    e.preventDefault();
    navigate(`/partywears/${data._id}`); // Navigate to the product page
  };

  // Handle image load event
  const handleImageLoad = () => {
    setImageLoading(false); // Set loading to false when the image is loaded
  };

  // Caching logic for product data
  useEffect(() => {
    const cacheKey = `product-${data._id}`;
    const cachedData = localStorage.getItem(cacheKey);
    const cacheTimeKey = `cache-time-${data._id}`;
    const currentTime = Date.now();

    // Check if the cached data is still valid (30 minutes)
    if (cachedData && currentTime - localStorage.getItem(cacheTimeKey) < 30 * 60 * 1000) {
      const cachedProduct = JSON.parse(cachedData);
      // Update the state with cached product data if necessary
      // You can set the product data directly if needed
      console.log('Using cached data:', cachedProduct);
    } else {
      // Fetch and cache the product data if not found or expired
      fetch(`${WebAPI}/partydproduct/${data._id}`)
        .then(res => res.json())
        .then(productData => {
          localStorage.setItem(cacheKey, JSON.stringify(productData));
          localStorage.setItem(cacheTimeKey, currentTime); // Set cache time
        })
        .catch(error => {
          console.error('Error fetching product data:', error);
        });
    }
  }, [data._id]);

  return (
    <div>
      {isLoading ? (
        <Box>
          <Skeleton width={"100%"} h='400px' />
          <Box padding={"10px"}>
            <Box h={"110px"}>
              <SkeletonText mt='3' noOfLines={3} spacing='4' skeletonHeight='4' />
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <SkeletonCircle size='8' />
              <Skeleton>
                <Button
                  onClick={(e) => addToCart(e, data)}
                  border={"none"}
                  borderRadius='1px'
                  color={"white"}
                  width={"60%"}
                  height={"28px"}
                  fontSize={'0.8rem'}
                  fontWeight={'500'}
                >
                  ADD TO BAG
                </Button>
              </Skeleton>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box border={"1px solid #e5e5e5"}>
          <div onMouseEnter={() => setHoverImage(true)} onMouseLeave={() => setHoverImage(false)}>
            <div position='relative' zIndex={'100'}>
            <Box 
  textAlign={'center'} 
  fontSize={{ base: '8px', md: '12px' }}  // Smaller font size on mobile (base), larger on desktop (md)
  display={'flex'} 
  justifyContent='center' 
  alignItems={'center'} 
  color={'white'} 
  backgroundColor={'rgb(50,50,50)'} 
  marginTop={'5px'} 
  marginLeft={'5px'} 
  width={{ base: '30px', md: '45px' }}  // Smaller width on mobile, larger on desktop
  h={{ base: '30px', md: '45px' }}      // Smaller height on mobile, larger on desktop
  borderRadius={'50%'} 
  position={'absolute'} 
  fontWeight='500'
>
  {data.discount}% OFF
</Box>              <Link to={`/partywears/${data._id}`}>
                {imageLoading && <Skeleton width={"100%"} h='400px' />} {/* Loader while image is loading */}
                <img 
                  src={`${WebAPI}/uploads/${imgSrc}`} 
                  alt="" 
                  width={"100%"} 
                  onLoad={handleImageLoad} // Handle image load
                  style={{ display: imageLoading ? 'none' : 'block' }} // Hide image until loaded
                />
                <img 
                  src={data.img2} 
                  alt="" 
                  width={"100%"} 
                  style={{ display: imageLoading ? 'none' : 'block' }} // Hide image until loaded
                />
              </Link>
            </div>
          </div>
          <Box padding={"10px"}>
            <Box marginBottom={'12px'}>
              <Box overflow={'hidden'} h={'20px'}>
                <Text fontSize="sm">{data.title}</Text>
              </Box>
              <h3 className="price">
                <span className="currentPrice">Rs.  {data.price1}</span>&nbsp;&nbsp;{" "}
                <span className="originalPrice">Rs.  {data.price2}</span>
              </h3>
              {/* <Box>
                <h6 className="deliveryInfo">
                  <TbTruckDelivery size={"1.4rem"} color={"black"} /> &nbsp;
                  <span>EXPRESS </span>&nbsp;<span>3 Day Delivery</span>
                </h6>
              </Box> */}
              <Box>
  <h6 className="stockInfo mobile-hidden" style={{ color: data.quantity < 11 ? 'red' : 'black' }}>
    📦 &nbsp;
    <span>Available Stock: </span>&nbsp;<span>{data.quantity} Pieces</span>
    {data.quantity < 11 && (
      <Box
        as="span"
        backgroundColor="red"
        color="white"
        fontSize="0.8rem"
        fontWeight="bold"
        padding="4px 8px"
        borderRadius="8px"
        marginLeft="10px"
      >
        Limited Stock
      </Box>
    )}
  </h6>
</Box>


            </Box>
            <Box alignItems={"center"}>
              
              <div className="cartBtns">
              <p className="addToCart" onClick={(e) => addToCart(e, data)}>
                ADD TO CART
              </p>
            </div>
              
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};
