import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import WebAPI from '../../../env';

const ImageSet1 = ({ children = null, products = [], classname, title }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleGetTheLook = () => {
    navigate(`/`); // Navigate to the product page
  };

  return (
    <div>
      <h1 className='title'>{title}</h1>
      {children}
      <div className={classname}>
        {
          products.map((product, ind) => {
            return (
              <div className="image-container" key={ind}>
                <a href={`/nightwears/${product._id}`}>
                  <img 
                    src={`${WebAPI}/uploads/${product.image2}`} 
                    alt={`Product ${ind + 1}`} 
                    className="image-size" 
                  />
                  <div className="overlay">
                    <div className="price-overlay">
                      Rs.{product.price1} -- {product.discount ? `${product.discount}% OFF` : '' }
                    </div>
                    <div className="title-overlay">
                      {product.title || 'Product Title'} {/* Display product title */}
                    </div>
                    
                  </div>
                </a>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

export default ImageSet1;
