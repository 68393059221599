import React from 'react';
import { useRef } from 'react';
import {
  Box,
  Text,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  FormControl,
  TableContainer,
  Input,
  Button,
  Divider,
  Center,
  Flex,
} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa'; // Import icons from react-icons

import source1 from './Footer-Images/footer_image1.png';
import source2 from './Footer-Images/footer_image2.png';
import source3 from './Footer-Images/footer_image3.png';
import source4 from './Footer-Images/footer_image4.png';
import source5 from './Footer-Images/footer_image5.png';

function Footer() {
  // media query
  const [isLargerThan500] = useMediaQuery('(max-width: 500px)');
  const containerRef = useRef(null);

  return (
    <Box backgroundColor={'#fbfbfb'}>
      <Box
        padding={'8px'}
        boxShadow="xs"
        p="6"
        textAlign={'left'}
        ref={containerRef}
        w="76%"
        margin={'auto'}
        overflowY={'auto'}
        maxHeight={'400px'}
        fontFamily={'Poppins, sans-serif'}
        backgroundColor={'#fbfbfb'}
      >
        {/* Existing Text Elements */}
        <Text top={'0px'} color={'black'} fontWeight={'semibold'} w={'72.5%'} backgroundColor={'white'} fontSize={'16px'} as="abbr">
          ONLINE SHOPPING SITE FOR WOMEN’S FASHION - Niteress
        </Text>
        <Text fontSize={'13px'}>
          Niteress.com is an online fashion store for taste-makers and trend-breakers all over the country. When it comes to online
          shopping for women looking for the latest fashion trends, Niteress is the one-stop destination. Started in 2012, this
          online fashion store comprises of the trendiest tops, hottest dresses, skirts, jackets, shoes, bags, accessories and
          fashion jewellery for women online shopping. Every new season brings fresh and latest fashion for women. And it becomes
          important for you to keep up with the current trend. However, Niteress has all your styling stress sussed!
        </Text>
        {/* Additional Text Elements... */}
        
        <Text fontSize={'13px'}>
          When it comes to online shopping sites for women outfits, Niteress comprises the chicest apparel for girls, ranging from Tops, hottest Dresses, Shirts, Jackets, Skirts, Jeans, Blazers, Pants, and many more. And when you slip into one of these outfits, we make sure that we get all the spotlight on you. Just tag us and get featured on our website. You go, girl!
        </Text>
        <Text fontWeight={'semibold'} fontSize={'13px'}>
          Our Popular Categories: Women Tops | Women Dresses | Women Skirts | Women Bottoms Pants | Summer Collection for Women | Women Loungewear | Winter Wear for Women
        </Text>
      </Box>
      <br />

      {/* Image Section */}
      {/* <Box padding={'8px'} boxShadow="xs" display={'flex'} w={'75%'} margin={'auto'} justifyContent={'space-between'}>
        <Image w={isLargerThan500 ? '100%' : '100%'} height={'120'} src={source1} alt="image1"></Image>
      </Box> */}
      <br />

      {/* Newsletter Signup */}
      <Box
        boxShadow="none"
        w={isLargerThan500 ? '75%' : '100%'}
        margin="auto"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box w={isLargerThan500 ? '100%' : '90%'} textAlign="center">
          <Text color="black" fontWeight="semibold" fontSize={isLargerThan500 ? '13px' : '20px'}>
            NEWSLETTER SIGNUP
          </Text>
          <br />
          <Box>
            <FormControl display="flex" justifyContent="center" alignItems="center" w="100%">
              <Input w={isLargerThan500 ? '60%' : '70%'} placeholder="Enter Email Address" type="email" />
              <Button color="white" ml="10px" colorScheme="teal" backgroundColor="black" border="none" boxShadow="none">
                Subscribe
              </Button>
            </FormControl>
          </Box>
          <br />
        </Box>
      </Box>

      <Center mt={'10px'} w={'70%'} margin={'auto'}>
        <Divider orientation="horizontal" />
      </Center>
      <br />

      {/* Footer Line with Social Icons */}
      <Box textAlign="center" padding="20px">
        <Text fontSize="14px" color="gray.600">
          © 2024 Niteress. All rights are reserved.
        </Text>
        <Flex justifyContent="center" marginTop="10px">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebook size={20} style={{ margin: '0 10px', color: '#4267B2' }} />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={20} style={{ margin: '0 10px', color: '#E4405F' }} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter size={20} style={{ margin: '0 10px', color: '#1DA1F2' }} />
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={20} style={{ margin: '0 10px', color: '#0077B5' }} />
          </a>
        </Flex>
      </Box>
    </Box>
  );
}

export default Footer;
