import Banner from "../../Components/Landing/Banner";
import Navbar from "../../Components/Navbar/Navbar";
import Trending from "../../Components/Landing/Trending";
import SingleImg from "../../Components/Landing/SingleImg";
import ImageSet from "../../Components/Landing/ImageSet";
import CalltoAction from "../../Components/Landing/CalltoAction";
import ProductCarousel from "../../Components/Landing/ProductCarousel";
import InstaShop from "../../Components/Landing/InstaShop";
import { useEffect, useState } from "react";
import Scroll2Top from "../../Components/Scroll2Top/Scroll2Top";
import Chatbot from "../../Components/ChatBot/Chatbot";
import WebAPI from "../../env";
import ImageSet1 from "../../Components/Landing/ImageSet/ImageSet1";
import ImageSet2 from "../../Components/Landing/ImageSet/ImageSet2";

const Home = ()=>{
    const [visible, setVisible] = useState(false)
    const [products, setProducts] = useState([]); // State to store fetched products
    const [partyproducts, setpartyproducts] = useState([]); // State to store fetched products
    
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
      };

      window.addEventListener('scroll', toggleVisible);
      useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`${WebAPI}/nightyproduct`); // Fetch products from the API
                const data = await response.json(); // Assuming data is an array of products
                setProducts(data.slice(0, 5)); // Store only the first 5 products
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };
        const fetchpartyProducts = async () => {
          try {
              const response = await fetch(`${WebAPI}/partydproduct`); // Fetch products from the API
              const data = await response.json(); // Assuming data is an array of products
              setpartyproducts(data.slice(0, 4)); // Store only the first 5 products
          } catch (error) {
              console.error("Error fetching products:", error);
          }
      };

      fetchpartyProducts();
      fetchProducts();

        window.addEventListener('scroll', toggleVisible);
        return () => window.removeEventListener('scroll', toggleVisible); // Cleanup on unmount
    }, []);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    return (
        <div style={{zIndex:'1',marginBottom:'3%'}}>
            
            <Banner />
            <Trending />
            <SingleImg title='HOT RIGHT NOW' url='https://img.faballey.com/images/banner/13261f57-0431-4ccc-965c-6eeb9aaf64fd.jpg'/>

            <ImageSet1
                title="DEALS YOU DON'T WANT TO MISS"
                classname='dealsImg'
                products={products}
            />
            <InstaShop />
            <ImageSet2 
                title="EXPLORE MORE"
                classname='exploreImg'
                para="Invest in trends you'll wear on repeat"
                products={partyproducts}
            > </ImageSet2>
            
            <CalltoAction />
            {visible?<Scroll2Top/>:""}
            <Chatbot/>
        </div>
    )
}

export default Home;