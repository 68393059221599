import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import WebAPI from '../../../env';

const ImageSet2 = ({ children = null, products = [], classname, title }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleGetTheLook = () => {
    navigate(`/`); // Navigate to the product page
  };
  return (
    <div>
      <h1 className='title'>{title}</h1>
      {children}
      <div className={classname}>
        {
          products.map((product, ind) => {
            return (
              <div className="image-container" key={ind}>
                <a href={`/partywears/${product._id}`}> {/* Change to your product detail page URL */}
                  <img 
                    src={`${WebAPI}/uploads/${product.image3}`} 
                    alt={`Product ${ind + 1}`} 
                    className="image-size" 
                  />
                  <div className="overlay">
                    <div className="price-overlay">
                    Rs.{product.price1} 
                    </div>
                    <div className="add-to-cart-container">
  <Link className="add-to-cart-link" onClick={handleGetTheLook}>ADD TO CART</Link>
</div>

                  </div>
                </a>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

export default ImageSet2;
