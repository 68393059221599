import React, { useState } from 'react';
import './OrderTracking.css';
import Navbar from '../../Components/Navbar/Navbar';
import WebAPI from '../../env';

const OrderTracking = () => {
  const [orderStatus, setOrderStatus] = useState({
    orderId: '',
    shippingId: '',
    expectedArrival: '',
    orderDate: '',
    status: '',
    customerName: '',
    customerEmail: '',
    customerAddress: '',
  });

  const [inputOrderId, setInputOrderId] = useState('');
  const [isOrderSearched, setIsOrderSearched] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    setInputOrderId(e.target.value);
  };
  const getProgressClass = () => {
    switch (orderStatus.status) {
      case 'Confirmed':
        return 'completed-pending';
      case 'Patched':
        return 'completed-shipped';
      case 'On the way': // Ensure the case matches exactly with the response
        return 'completed-enRoute';
      case 'completed':
        return 'completed-arrived';
      default:
        return ''; // No progress class if status is not recognized
    }
  };
  const handleTrackOrder = async () => {
    if (inputOrderId) {
      try {
        // Make the API call to fetch the order details
        const response = await fetch(`${WebAPI}/order-status/${inputOrderId}`);

        // Check if the request was successful
        if (!response.ok) {
          throw new Error('Order not found or failed to fetch details');
        }

        // Parse the JSON response
        const data = await response.json();

        // Format the order date
        const orderDate = new Date(data.orderDate);
        const formattedOrderDate = `${orderDate.getDate()}/${orderDate.getMonth() + 1}/${orderDate.getFullYear()}`;

        // Calculate expected arrival date (4 days later)
        const estimatedArrival = new Date(orderDate);
        estimatedArrival.setDate(orderDate.getDate() + 4);
        const formattedArrivalDate = `${estimatedArrival.getDate()}/${estimatedArrival.getMonth() + 1}/${estimatedArrival.getFullYear()}`;

        // Update the state with the fetched order details
        setOrderStatus({
          orderId: data.orderId,
          shippingId: data.shippingId || 'N/A',
          expectedArrival: formattedArrivalDate,
          orderDate: formattedOrderDate,
          status: data.orderStatus || 'pending', // defaulting to pending if not available
          customerName: data.customerName || 'Unknown',
          customerEmail: data.customerEmail || 'Unknown',
          customerAddress: data.customerAddress || 'Unknown',
        });

        // Clear any error messages
        setErrorMessage('');
        // Show the order details and progress bar
        setIsOrderSearched(true);
      } catch (error) {
        console.error('Error fetching order details:', error.message);
        setErrorMessage(error.message);
        setIsOrderSearched(false); // Hide the order details if there's an error
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="tracking-container">
        <h2>My Order / Tracking</h2>
        <hr />
        <br />

        {/* Order ID Input Section */}
        <div className="order-id-input">
          <label htmlFor="orderId">Enter your Order ID</label>
          <input
            type="text"
            id="orderId"
            value={inputOrderId}
            onChange={handleInputChange}
            placeholder="Enter your order ID here"
            className="order-id-field"
          />
          <button onClick={handleTrackOrder} className="track-button">
            Track Order
          </button>
        </div>

        {/* Show error message if there's an issue */}
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {/* Conditionally Render the Order Details and Progress Bar */}
        {isOrderSearched && (
          <>
            {/* Order Details Section */}
            <div className="order-details">
              <p>
                <strong>{orderStatus.customerName}</strong>
              </p>
              <p>{orderStatus.customerEmail}</p>
              <p>Order ID: {orderStatus.orderId}</p>
              <p>Address: {orderStatus.customerAddress}</p>
              <br />
              <table className="tracking-table">
                <thead>
                  <tr>
                    <th>Order Date</th>
                    <th>Estimated Delivery Time</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{orderStatus.orderDate}</td>
                    <td>{orderStatus.expectedArrival}</td>
                    <td>
                      {orderStatus.status.charAt(0).toUpperCase() + orderStatus.status.slice(1)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />

            <div className={`progress-line ${getProgressClass()}`}>
  {/* Node 1: Order Confirmed */}
  <div className={`status-point ${orderStatus.status === 'Confirmed' || orderStatus.status === 'Patched' || orderStatus.status === 'On the way' || orderStatus.status === 'completed' ? 'completed' : ''}`}>
    <div className="point">{orderStatus.status === 'Confirmed' || orderStatus.status === 'Patched' || orderStatus.status === 'On the way' || orderStatus.status === 'completed' ? '✓' : ''}</div>
    <p>Order Confirmed</p>
  </div>

  {/* Node 2: Placed by Courier */}
  <div className={`status-point ${orderStatus.status === 'Patched' || orderStatus.status === 'On the way' || orderStatus.status === 'completed' ? 'completed' : ''}`}>
    <div className="point">{orderStatus.status === 'Patched' || orderStatus.status === 'On the way' || orderStatus.status === 'completed' ? '✓' : ''}</div>
    <p>Placed by Courier</p>
  </div>

  {/* Node 3: On the Way */}
  <div className={`status-point ${orderStatus.status === 'On the way' || orderStatus.status === 'completed' ? 'completed' : ''}`}>
    <div className="point">{orderStatus.status === 'On the way' || orderStatus.status === 'completed' ? '✓' : ''}</div>
    <p>On the Way</p>
  </div>

  {/* Node 4: Order Completed */}
  <div className={`status-point ${orderStatus.status === 'completed' ? 'completed' : ''}`}>
    <div className="point">{orderStatus.status === 'completed' ? '✓' : ''}</div>
    <p>Order Completed</p>
  </div>
</div>


          </>
        )}
      </div>
    </div>
  );
};

export default OrderTracking;
