import React from "react";
import styles from "../../Pages/Cart/Cart.module.css";
import { MdDelete } from "react-icons/md";
import { BsPlusCircle } from "react-icons/bs";
import { SlMinus } from "react-icons/sl";
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import WebAPI from "../../env";

const CartItemsCard = ({ Cartdata = { category: '', color: '', qty: 1, price1: 0, price2: 0, img1: '', title: '', uniqueId: '', id: '', size:'' }, DeleteFunction, updateQuantity }) => {

  let [RandomSize, setRandomSize] = useState('');
  let [RandomSKUCode, setRandomSKUCode] = useState('');
  let [colorName, setColorName] = useState('');
 
  useEffect(() => {
    if (Cartdata && Cartdata.category && Cartdata.color) {
      random_item(items);
      generateString(8);
      ColorString();
    }
  }, [Cartdata]);

  let [quantity, setQuantity] = useState(Cartdata.qty);

  // Random String Generation for SKU Code
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
  useEffect(() => {
    updateQuantity(Cartdata.id, quantity); // Pass quantity change to the Cart component
  }, [quantity]);

  function Add() {
    setQuantity((prev) => prev + 1);
  }

  function Minus() {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  }
  function generateString(length) {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    // Safeguard for undefined Cartdata.category
    const category = Cartdata.category ? Cartdata.category.toUpperCase() : 'DEFAULT_CATEGORY';
    let string = category + "-" + result;
    setRandomSKUCode(string);
  }

  // Random Size Selection
  var items = ['M', 'S', 'L', 'XL'];
  function random_item(items) {
    let size = items[Math.floor(Math.random() * items.length)];
    setRandomSize(size);
  }

  // Capitalize Color Name
  function ColorString() {
    let string = Cartdata.color || 'defaultcolor';  // Safeguard for undefined color
    let newstr = '';
    let FirstChar = string.charAt(0).toUpperCase();
    for (let i = 1; i < string.length; i++) {
      newstr += string.charAt(i);
    }
    newstr = FirstChar + newstr;
    setColorName(newstr);
  }

  return (
    <div>
      <div className={styles.IndivCartDetailCard}>
        <div className={styles.ProductImage}>
          <img src={`${WebAPI}/uploads/${Cartdata.img1}`} alt={Cartdata.title} />
        </div>
        <div className={styles.ProductDetailsMainDiv}>
          <div className={styles.UpperProductDetailsDiv}>
            <div className={styles.UpperLeftProductDetailsDiv}>
              <Link to={`/products/${Cartdata.uniqueId}`}>{Cartdata.title}</Link>
              <div className={styles.QtySizeColorDetailsDiv}>
                <p>Size: {Cartdata.size || 'N/A'}</p>
                <p>Color: {colorName}</p>
                
              </div>
            </div>
            <div className={styles.UpperRightProductDetailsDiv}>
              <p className={styles.ProductPriceInCart}>
                Rs. {Cartdata.price1 * quantity}
              </p>
              &nbsp;
            </div>
          </div>
          <div className={styles.LowerProductDetailsDiv}>
            <div className={styles.EditDetails}>
              <div>
                <button
                  onClick={() => Add(Cartdata.id)}
                  disabled={quantity < 5 ? false : true}
                  style={quantity === 5 ? { color: "gray" } : { color: "rgb(49, 49, 49)" }}
                >
                  <BsPlusCircle />
                </button>
                <p>{quantity}</p>
                <button
                  onClick={() => Minus(Cartdata.id)}
                  disabled={quantity > 1 ? false : true}
                  style={quantity === 1 ? { color: "gray" } : { color: "rgb(49, 49, 49)" }}
                >
                  <SlMinus />
                </button>
              </div>
              <p>Qty: {quantity}</p>
            </div>
            <div className={styles.DeleteCartItem}>
              <MdDelete
                className={styles.ReactDeleteIcon}
                onClick={() => {
                  DeleteFunction(Cartdata.id);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItemsCard;
