import { Box, useToast, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CategoryList } from '../../Components/Nightwears/CategoryList/CategoryList';
import { ProductList } from '../../Components/Nightwears/ProductList/ProductList';
import WebAPI from '../../env';

export const Nightwears = () => {
  const [nightproducts, setnightProducts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [isMobile] = useMediaQuery("(max-width: 768px)"); // Define mobile view width breakpoint
  const [filters, setFilters] = useState({
    category: '',
    color: '',
    sleeves: '',
    length: '',
    discount: ''
  });
  const toast = useToast();
  const [sort, setSort] = useState('');
  const [images, setImages] = useState({});

  // Function to fetch product images by product ID
  const fetchProductImages = async (productId) => {
    try {
      const response = await fetch(`${WebAPI}/nightyproduct/${productId}/images`);
      if (!response.ok) {
        throw new Error('Failed to fetch product images');
      }
      const images = await response.json();
      return images; // Contains image1, image2, image3, image4
    } catch (error) {
      console.error('Error fetching product images:', error);
      return null;
    }
  };

  const changeFilter = (key, value) => {
    setFilters((prevFilters) => {
      if (key === 'category') {
        const newCategories = prevFilters.category.includes(value)
          ? prevFilters.category.filter((item) => item !== value) // Uncheck category
          : [...prevFilters.category, value]; // Add category

        return { ...prevFilters, category: newCategories };
      } 
      return prevFilters;
    });
  };

  const changeSort = (value) => {
    setSort(value);
  };

  const changePages = (value) => {
    setCurrentPage((prev) => prev + value);
  };

  const changeByClick = (value) => {
    setCurrentPage(value);
  };

  const fetchProducts = async () => {
    try {
      let url = `${WebAPI}/nightyproduct`;

      // Check if category filters are applied
      if (filters.category.length > 0) {
        url = `${WebAPI}/nighty-specifc-category/${filters.category.join(',')}`; // Send multiple categories as a comma-separated string
      }

      const response = await fetch(url);
      const data = await response.json();
      setnightProducts(data);
      setTotalProducts(data.length);
      setLoading(false);
      window.scrollTo(0, 0);

      // Fetch images for each product
      const imagesData = {};
      for (const product of data) {
        const productImages = await fetchProductImages(product._id);
        if (productImages) {
          imagesData[product._id] = productImages;
        }
      }
      setImages(imagesData); // Store images by product ID
    } catch (error) {
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [filters, sort, currentPage]);

  const postData = async (propdata) => {
    let newData = { ...propdata };
    delete newData.id;
    newData.qty = 1;
    toast({
      title: "Successfully Added to Cart",
      status: 'success',
      duration: 2000,
      position: 'top'
    });
  };

  const addToCart = async (e, propdata) => {
    e.preventDefault();
    const isAlreadyInCart = false; // Simulate cart check
    if (isAlreadyInCart) {
      toast({
        title: "Product is Already in Cart",
        status: 'error',
        duration: 2000,
        position: 'top'
      });
    } else {
      postData(propdata);
    }
  };

  return (
    <Box
      m={isMobile ? '0' : 'auto'}  // Remove margin on mobile
      marginTop={'30px'}
      width={isMobile ? '100%' : '80%'} // Full width on mobile, 80% on desktop
      display={'flex'}
      justifyContent={'space-between'}
      gap={'15px'}
    >
      {/* Conditionally hide CategoryList on mobile screens */}
      {!isMobile && <CategoryList changeFilter={changeFilter} />}
      <ProductList 
        products={nightproducts} 
        addToCart={addToCart} 
        isLoading={isLoading} 
        changeSort={changeSort} 
        sort={sort} 
        totalProducts={totalProducts} 
        filters={filters} 
        currentPage={currentPage} 
        changeByClick={changeByClick} 
        setCurrentPage={changePages} 
        images={images} // Pass the images to ProductList
      />
    </Box>
  );
};
