import React from 'react';
import './Navbar.css';
import MidbarCard from './MidbarCard';
import { Link } from 'react-router-dom';

const MidbarList = () => {
  return (
    <div>
        <ul className='navList'>
            <li><Link to={'/products'}> TOPS </Link>
              <MidbarCard 
               list={['PEPLUM  TOP', 'FLORAL  PRINTED', 'OFF  SHOULDER', 'COTTON  SHIRTS']}
               images={[
                'https://impulse-theme-fashion.myshopify.com/cdn/shop/articles/Stocksy_txp20325e509vv100_Medium_1336830.jpg?v=1525115138&width=720',
                'https://images.pexels.com/photos/3024256/pexels-photo-3024256.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                'https://images.pexels.com/photos/4687065/pexels-photo-4687065.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                'https://images.pexels.com/photos/6925401/pexels-photo-6925401.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
               ]}
               links={[
                '/products',
                '/products',
                '/products',
                '/products'
               ]}
              />
            </li>
            <li><Link to={'/nightwears'}>NIGHTWEARS</Link>
              <MidbarCard 
               list={['SEXY NIGHTY',  'BRIDAL', 'BODY STOCKINGS', 'CAMISOLE']}
               images={[
                'https://images.pexels.com/photos/24205557/pexels-photo-24205557/free-photo-of-a-woman-lying-in-bed.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                'https://images.pexels.com/photos/7470465/pexels-photo-7470465.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                'https://images.pexels.com/photos/1615799/pexels-photo-1615799.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                'https://img.freepik.com/free-photo/pretty-sensual-blonde-model-laying-bed-enjoy-her-morning-luxury-hotel-wearing-burgundy-silk-nightdress-robe-blind-hairs-beauty-face-boudoir-style_291049-1413.jpg?t=st=1727044410~exp=1727048010~hmac=220524e0b5e1cb3b86f7987edbc99c67aee042a7dd0257127802d6e543a9d0ee&w=1380'
               ]}
               links={[
                '/nightwears',
                '/nightwears',
                '/nightwears',
                '/nightwears'
               ]}
              />
            </li>
            <li><Link to={'/partywears'}>PARTYWEARS</Link>
              <MidbarCard 
               list={['MINI DRESS','LACE MIDI', 'MAXI DRESS','OFF SHOULDER']}
               images={[
                'https://img.freepik.com/free-photo/attractive-happy-smiling-woman-stylish-black-dress_285396-8143.jpg?t=st=1727044612~exp=1727048212~hmac=e4a5f2d8cb8f79e7bed321a0a28daaa43d13893366df1c44d852e8b07cef8f7d&w=1380',
                'https://images.pexels.com/photos/2584336/pexels-photo-2584336.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                'https://img.freepik.com/free-photo/young-stylish-asian-woman-white-boho-dress-vintage-style-natural-smiling-happy-tropical-vacation-hotel-palm-trees-background_285396-5870.jpg?t=st=1727044735~exp=1727048335~hmac=f5f87deffea6b4d1e281af0370ac95824ec644e0598e438ea934b1ca395a8dfb&w=1380',
                'https://img.freepik.com/free-photo/young-beautiful-confident-female-trendy-summer-evening-silver-dress-sexy-carefree-blond-woman-posing-near-white-wall-studio-model-with-red-lips-isolated_158538-22115.jpg?t=st=1727044847~exp=1727048447~hmac=285eec8514b934f682bfee070156640c6133d022873d25bcaff5574917af3423&w=1380'
               ]}
               links={[
                '/partywears',
                '/partywears',
                '/partywears',
                '/partywears'
               ]}
              />
            </li>   
            <li><Link to={''}>END OF SESSION SALE</Link></li>
        </ul>
    </div>
  );
}

export default MidbarList;
